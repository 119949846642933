// страница 404

const NotFound = () => {
  return (
    <div className="alphaback">
      {" "}
      <h3>
        <b> Cтраница не найдена </b>
      </h3>
    </div>
  );
};

export default NotFound;
