// страница отображения карты пещер
import {
  YMaps,
  Map,
  Placemark,
  GeolocationControl,
  ZoomControl,
  TypeSelector,
  RulerControl,
} from "react-yandex-maps";

import convert from "geo-coordinates-parser";

var jsonTest = require("../data/testOneCave.json");
var json = [jsonTest];
const MapOneCave = (props) => {
  const mapOptions = {
    modules: ["geocode", "SuggestView"],
    defaultOptions: { suppressMapOpenBlock: true },
    width: "100%",
    height: 400,
  };

  const geolocationOptions = {
    defaultOptions: { maxWidth: 128 },
    defaultData: { content: "Где я?" },
  };

  const initialState = {
    title: props.cave_name,
    center: [
      convert(props.location).decimalLatitude,
      convert(props.location).decimalLongitude,
    ],
    zoom: 15,
    type: "yandex#hybrid",
  };

  return (
    <div>
      <font size="3">
        Пещера: <b>{props.cave_name}</b>
      </font>
      <YMaps
        query={{
          apikey: "29294198-6cdc-4996-a870-01e89b830f3e",
          // load: "package.full",
          // lang: "en_RU",
        }}
      >
        <Map
          {...mapOptions}
          state={initialState}
          modules={[
            // "templateLayoutFactory",
            // "option.presetStorage",
            // "option.Manager",
            // "control.ZoomControl",
            // "control.FullscreenControl",
            //"geoObject.addon.balloon",
            "geoObject.addon.hint",
          ]}
        >
          <GeolocationControl {...geolocationOptions} />
          <ZoomControl />
          <TypeSelector />
          <RulerControl options={{ float: "right" }} />

          <Placemark
            geometry={[
              convert(props.location).decimalLatitude,
              convert(props.location).decimalLongitude,
            ]}
            properties={{
              hintContent: props.cave_name,
              balloonContent: "Это балун",
            }}
            options={{
              iconLayout: "default#image",
              iconImageHref:
                "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAADwUlEQVRIia2UzW8bRRjGn5nZnf3y7tq79tpO7MRJkzht1YRGhLaAkIoTI6sS/ZAqVAlRofaAOCC4AGlAsrhw4xDUphHlBBIS/0BEgQMHRM+NokTqR5paQZXiJCQ0mNiOh0NpEsfbQKLMbd55nvnNs/PuALsYN/KvOdc+PhXajYf+X+H1ocwhAjGtKJXpsaFs974DJC6N9XUEw0c7bY8puLGvgKvD2aQik7QdkEkwwKFItOvqcDa5bwDOcKnV0za+faunhTipXdoz4OsPXzJHhwfPCoAAAKW4GHVV6el6zNUkyujFPQNoIPiZFdC+G/0ke/ralUyPqUqGzDalEiMwNcn48tOBI3sCAOL1F57rVHTO31S59E4ypoW3K1qiWlin5N1dA766komqKg/EvRAEqb0oBM5GLIUAQKVaQ6VaAwBEgiqBIGfy+fyO9yhtLwjQXFsiahJCEDINrVJeJZQS/L6whrmFMgAg4XDEwwpcmytr87+9Ojo8aMoy/eVy/ofF/0zANeVCc8zRAKA1EbF1VbZLazUUimXkTvYjd7IfDxfKKK3VkPD0kKbTb7va4t9Qym59nz/PdwQ8iSt6HdsAADTHXFIqC0wVVnHsaBcYo2CM4lhfGlOFVTACBK1A5Hhf2uhub/KWqssNrVsHiFV+7Qs7FgMhAABDV1EuC9imhWh48wmKukHYlomJmT/RczBFASB9IGFzLr2/I4DL7FwqEXa31jra4ni+t3O7D/29XZAVBdGwDQBQFRmGpoTGPhpoeSaAMnamKeqSrbXD6VZw3tALkGUJgy/3gJBN+YFUPCjp0hu+gJF8zpJlyVW43LDZs4amKnXzVHNEZpS+tbW2cTReXc8mm8K630Y1IfDzzQlMT84BALoPNyOTPQJK6sJCVTk4597IUC7y3ufj83UJdE2+0BJ3A36Am+O3UbhXRDLiIBlxULhbxI/jt31TtSc9S2HVc0/nG4D1mjjhOlaDYWWlhPt3HsE1DRA8ef0cy8C9O4+wslJq0KeSUVVV+Nt1gOtDmUMhO8C2RwaAyYkCdF7//xAAuswxOVFo0JuGCspo+0g+Z20AJFU+nUp4jl/k2Zl5GNsuEwAMVcHsTNHPglTC03mlemoTQOn5Ji/U2IsAlhZXwSXWUJdlhqXFx76AtqRnaKpyGQDIFx+c0AzDLCabw3/5iW/9dNe1A5oQEBDrgggCQQkhhBCx/LhEjg90LPj5Hs4V9fIycyQAWK/ilQez/nEVSzr4NyoAAMFqgtSIACH03zU8mC1O+RoBVKU/6D9GsOoJhFP+cgAAAABJRU5ErkJggg==",
              iconImageSize: [24, 24],
              iconImageOffset: [0, 0],
            }}
          />
        </Map>
      </YMaps>
    </div>
  );
};

export default MapOneCave;
