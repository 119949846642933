import './App.css';
import 'antd/dist/antd.css'
import LayoutPage from './components/LayoutPage';
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutPage page={"main"} />} />
        <Route path="/map_v1" element={<LayoutPage page={"devices"} />} />
        <Route path="/map_v2" element={<LayoutPage page={"cavemap"} />} />
        <Route path="/forum" element={<LayoutPage page={"info"} />} />
        <Route path="/caves/:id" element={<LayoutPage page={"cavecard"} />} />
        <Route path="/caves" element={<LayoutPage page={"cavelist"} />} />
        <Route path="/posts/:id" element={<LayoutPage page={"postcard"} />} />
        <Route path="*" element={<LayoutPage page={"404"} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
