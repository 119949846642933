// страница добавления пещеры

import {
  Upload,
  Input,
  message,
  Button,
  Card,
  Form,
  Divider,
  Select,
  InputNumber,
  Checkbox,
} from "antd";
import { useEffect, useState } from "react";
import { UploadOutlined, SendOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import CaveList from "./CaveList";
import { addPlatform } from "@capacitor/core";

const CaveAdd = () => {
  const [topoImgUrls, setTopoImgUrls] = useState([]); // массив топосьемок добавленных в загрузках
  const [caveImgUrls, setCaveImgUrls] = useState([]); // массив фоток добавленных в загрузках
  const [caveRegion, setCaveRegion] = useState("Агармышский"); // спелерайон по умолчанию
  const [isVerified, setIsVerified] = useState(0); // пещера по умолчанию не подтверждена
  const [backToCaveList, setBackToCaveList] = useState(false);

  const props = {
    name: "image",
    multiple: true,
    listType: "picture",
    maxCount: 100,
    action:
      "https://api.imgbb.com/1/upload?key=75dc7e6aa26326b5d8b2fc30689e8fa9",

    onChange(info) {
      if (info.file.status !== "uploading") {
        setCaveImgUrls([]);
        console.log(info.file, info.fileList);
        info.fileList.map((el) =>
          el.response
            ? setCaveImgUrls((oldArray) => [
                ...oldArray,
                el.response.data.image.url,
              ])
            : null
        );
      }

      if (info.file.status === "done") {
        message.success(`${info.file.name} загружен успешно`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} ошибка загрузки`);
      }
    },
  };

  const propsTopo = {
    name: "image",
    multiple: true,
    listType: "picture",
    maxCount: 100,
    action:
      "https://api.imgbb.com/1/upload?key=75dc7e6aa26326b5d8b2fc30689e8fa9",

    onChange(info) {
      if (info.file.status !== "uploading") {
        setTopoImgUrls([]);
        console.log(info.file, info.fileList);
        info.fileList.map((el) =>
          el.response
            ? setTopoImgUrls((oldArray) => [
                ...oldArray,
                el.response.data.image.url,
              ])
            : null
        );
      }

      if (info.file.status === "done") {
        message.success(`${info.file.name} загружен успешно`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} ошибка загрузки`);
      }
    },
  };

  const sendCave = async () => {
    // Дата добавления пещеры

    const upd_date = new Date().toLocaleString("ru", {
      year: "numeric",
      month: "short",
      day: "numeric",
      timezone: "UTC",
      hour: "numeric",
      minute: "numeric",
    });

    // Уникальный id пещеры

    const id =
      Math.random().toString(36).substring(2, 10) +
      Math.random().toString(36).substring(2, 10);

    // Название пещеры
    const cave_name = document.forms[0].elements[0].value;

    // Спелеорайон пещеры
    const cave_region = caveRegion;

    // Ссылки на добавленные фото
    const image = caveImgUrls.join();

    // Ссылки на добавленные топосъемки
    const topos = topoImgUrls.join();

    // Координаты пещеры
    const location = document.forms[0].elements[2].value;

    // Глубина пещеры
    const deep = document.forms[0].elements[5].value;

    // Протяженность пещеры
    const length = document.forms[0].elements[6].value;

    // Высота входа
    const alt_entrance = document.forms[0].elements[4].value;

    // Описание пещеры
    const history = document.forms[0].elements[7].value;

    // Координаты подтверждены?
    const is_verified = isVerified;

    var formdata = new FormData();

    formdata.append("cave_name", cave_name);
    formdata.append("upd_date", upd_date);
    formdata.append("cave_region", cave_region);
    formdata.append("topos", topos);
    formdata.append("image", image);
    formdata.append("location", location);
    formdata.append("history", history);
    formdata.append("deep", deep);
    formdata.append("length", length);
    formdata.append("alt_entrance", alt_entrance);
    formdata.append("is_verified", is_verified);
    formdata.append("id", id);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://followmytrack.ru/cave.php", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));

    setBackToCaveList(true);
  };

  return !backToCaveList ? (
    <div>
      <div className="alphaback" style={{ padding: "0 15px" }}>
        {/* <i>Добавить пещеру в каталог </i>{" "} */}
      </div>
      <div className="alphaback">
        <Card
          title="Новая пещера"
          headStyle={{ backgroundColor: "silver", border: 1 }}
          bodyStyle={{ backgroundColor: "rgba(220, 220, 220, 0.4)", border: 0 }}
          style={{ padding: "5px" }}
        >
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{
              border: "solid",
              padding: "15px",
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={sendCave}
            onFinishFailed={() => alert("Ошибка данных")}
            autoComplete="off"
          >
            <Divider orientation="left">Основное</Divider>
            <Form.Item
              name={["cave", "cave_name"]}
              label="Название пещеры"
              required
              extra="Название пещеры, через @ альтернативное название или кадастровый номер (например: Каскадная@КН 252-2)"
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
            <br />
            <Form.Item
              name={["cave", "cave_region"]}
              label="Спелеорайон"
              required
              extra="Выбор только из спелеорайонов главной гряды крымских гор"
            >
              {/* <Input style={{ width: "100%" }} /> */}
              <Select
                id="regionSelector"
                defaultValue="Агармышский"
                style={{
                  width: "100%",
                }}
                onChange={(el) => setCaveRegion(el)}
                options={[
                  {
                    value: "Агармышский",
                    label: "Агармышский",
                  },
                  {
                    value: "Ай-Петринский",
                    label: "Ай-Петринский",
                  },
                  {
                    value: "Бабугансий",
                    label: "Бабугансий",
                  },
                  {
                    value: "Байдаро-Балаклавский",
                    label: "Байдаро-Балаклавский",
                  },
                  {
                    value: "Восточно-Крымский",
                    label: "Восточно-Крымский",
                  },
                  {
                    value: "Восточно-Южнобережный",
                    label: "Восточно-Южнобережный",
                  },
                  {
                    value: "Демерджинский",
                    label: "Демерджинский",
                  },
                  {
                    value: "Долгорсковский",
                    label: "Долгорсковский",
                  },
                  {
                    value: "Западно-Южнобережный",
                    label: "Западно-Южнобережный",
                  },
                  {
                    value: "Карабийский",
                    label: "Карабийский",
                  },

                  {
                    value: "Качинский",
                    label: "Качинский",
                  },
                  {
                    value: "Никитско-Гурзуфский",
                    label: "Никитско-Гурзуфский",
                  },
                  {
                    value: "Салгиро-Индольский",
                    label: "Салгиро-Индольский",
                  },
                  {
                    value: "Судакский",
                    label: "Судакский",
                  },
                  {
                    value: "Чатырдагский",
                    label: "Чатырдагский",
                  },
                  {
                    value: "Ялтинский",
                    label: "Ялтинский",
                  },
                ]}
              />
            </Form.Item>
            <br />
            <Form.Item
              name={["cave", "location"]}
              label="Координаты"
              required
              extra="В формате градусы минуты и секунды (например: 44° 52′ 45.84″ 34° 34′ 8.10″)"
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>

            <br />
            <Form.Item
              name={["cave", "is_verified"]}
              label="Пещера подтверждена?"
              extra="Если координаты точные - поставьте флажок"
            >
              <Checkbox
                onChange={(el) => setIsVerified(+el.target.checked)}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <br />
            <Form.Item
              name={["cave", "alt_entrance"]}
              label="Высота входа н. у. м. "
            >
              <InputNumber
                min={1}
                max={5000}
                defaultValue={0}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <br />
            <Form.Item name={["cave", "deep"]} label="Относительная глубина ">
              <InputNumber
                min={1}
                max={5000}
                defaultValue={0}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <br />
            <Form.Item name={["cave", "length"]} label="Протяжённость ">
              <InputNumber
                min={1}
                max={5000}
                defaultValue={0}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <br />
            <Form.Item
              name={["cave", "history"]}
              label="Описание пещеры "
              required
              extra="Обязательно к заполнению для новой пещеры"
            >
              <TextArea style={{ width: "100%" }} />
            </Form.Item>

            <Divider orientation="left">Раздел загрузки</Divider>
            <Form.Item name={["cave", "image"]} label="Фотоматериалы ">
              <Upload {...props}>
                <Button icon={<UploadOutlined />}>
                  Загрузить (макс. 100 фото){" "}
                </Button>
              </Upload>
            </Form.Item>

            <br />
            <Form.Item name={["cave", "topos"]} label="Топосъемки ">
              <Upload {...propsTopo}>
                <Button icon={<UploadOutlined />}>
                  Загрузить (макс. 100 фото){" "}
                </Button>
              </Upload>
            </Form.Item>
            <Divider orientation="left">Все готово?</Divider>
            <Form.Item wrapperCol={{ offset: 8 }}>
              <Button
                icon={<SendOutlined />}
                htmlType="submit"
                // onClick={() => alert(topoImgUrls)}
              >
                Сохранить
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  ) : (
    <CaveList />
  );
};

export default CaveAdd;
