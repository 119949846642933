import * as constant from "./constant";
const phpURL = "https://followmytrack.ru"

// сгенерить новый id

export const generateNewId = () =>
    Math.random().toString(36).substring(2, 10) +
    Math.random().toString(36).substring(2, 10);


// получить текущую дату

export const upd_date = new Date().toLocaleString("ru", {
    year: "numeric",
    month: "short",
    day: "numeric",
    timezone: "UTC",
    hour: "numeric",
    minute: "numeric",
});


// получить пользователя по id

export const getUserById = async (id) => {
    console.log('transferedIdData=>  ', id)

    var formdata = new FormData();
    formdata.append("id", id);

    var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
    };

    try {
        const response = await fetch(`${phpURL}/userById.php`, requestOptions);
        const result = await response.json();
        console.log("UserData Dowmloaded", result);
        return result;
    } catch (error) {
        console.error(`Download error: ${error.message}`);
    }


}

// изменить аватарку пользователя по id

export const changeUserAvatar = async (id, photo) => {

    console.log('avatarChangeData=>  ', id, photo)

    var formdata = new FormData();
    formdata.append("id", id);
    formdata.append("photo", photo);

    var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
    };

    try {
        const response = await fetch(`${phpURL}/changeAvatar.php`, requestOptions);
        const result = await response.json();
        console.log("UserData Dowmloaded", result);
        return result;
    } catch (error) {
        console.error(`Download error: ${error.message}`);
    }


}



// добавить нового партнера

export const savePartners = async (data) => {

    const api = constant.serverAPI;

    const response = await fetch(`${api}/partner`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });
    if (!response.ok)
        return { code: 1, data: `Ошибка: ${response.status} - ${response.statusText}` };

    const result = await response.json();
    console.log('Outcoming /POST: ', result)
    return { code: 3, data: result };

}

// отредактировать нового партнера

export const modifyPartners = async (data) => {

    const api = constant.serverAPI;

    const response = await fetch(`${api}/partner`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });
    if (!response.ok)
        return { code: 1, data: `Ошибка: ${response.status} - ${response.statusText}` };

    const result = await response.json();
    console.log('Outcoming /PATCH: ', result)
    return { code: 3, data: result };

}

// найти и выдать все устройства

export const getDevices = async () => {

    const api = constant.serverAPI;

    const response = await fetch(`${api}/devices`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });
    if (!response.ok)
        return { code: 1, data: `Ошибка: ${response.status} - ${response.statusText}` };

    const result = await response.json();
    console.log('Incoming /GET: ', result)
    return { code: 3, data: result };

}

// отредактировать нового партнера

export const modifyDevices = async (data) => {

    const api = constant.serverAPI;

    const response = await fetch(`${api}/devices`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });
    if (!response.ok)
        return { code: 1, data: `Ошибка: ${response.status} - ${response.statusText}` };

    const result = await response.json();
    console.log('Outcoming /PATCH: ', result)
    return { code: 3, data: result };

}

//удалить устройство по id
export const removeDeviceById = async (idDevice) => {


    const api = constant.serverAPI;

    const response = await fetch(`${api}/device`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ _id: idDevice })
    });

    if (!response.ok)
        return { code: 1, data: `Ошибка: ${response.status} - ${response.statusText}` };

    const result = await response.json();
    console.log('Incoming /DELETE: ', result)
    return { code: 3, data: result };

}

// залогиниться

export const loginReq = async (data) => {
    console.log('input:', data)

    const api = constant.serverAPI;

    try {
        const response = await fetch(`${api}/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        });
        if (!response.ok)
            return { code: 1, data: `${response.status} - ${response.statusText}` };

        const result = await response.json();
        console.log('Outcoming /POST: ', result)
        return { code: 3, data: result };
    } catch (error) {
        console.error('Ошибка fetch:', error);
        return { code: 1, data: error }
    };
}

// зарегистрироваться

export const signinReq = async (data) => {
    console.log('input:', data)
    const api = constant.serverAPI;

    try {
        const response = await fetch(`${api}/signin`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        });
        if (!response.ok)
            return { code: 1, data: `${response.status} - ${response.statusText}` };

        const result = await response.json();
        console.log('Outcoming /POST: ', result)
        return { code: 3, data: result };
    } catch (error) {
        console.error('Ошибка fetch:', error);
        return { code: 1, data: error }
    };
}

// проверка сервера
export const checkServer = async () => {
    const api = constant.serverAPI;
    try {
        const response = await fetch(`${api}/status`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const result = await response.statusText;
        console.log('Incoming status: ', result)
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }


}