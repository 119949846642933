// Карточка записи (поста)

import React from "react";
import copy from "copy-to-clipboard";

import { Upload, Tag, List, Avatar, message, Image, Space } from "antd";
import { useEffect, useState } from "react";
import {
  UploadOutlined,
  SendOutlined,
  CaretDownOutlined,
  CaretRightOutlined,
  CaretUpOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

const PostCard = () => {
  const [spinner, setSpinner] = useState(false);
  const [cardData, setCardData] = useState(null);
  const [upd_key, upd_setKey] = useState(0);

  const [postData, setPostData] = useState("null"); //управляемая переменная описания

  const [editPost, setEditPost] = useState(false); // редактировать описание?

  useEffect(() => {
    readFromDb();
  }, [upd_key]);

  // чтение всех записей из таблицы пещеры

  function youtube_parser(url) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  }

  const readFromDb = async () => {
    setSpinner(true);
    try {
      const response = await fetch(
        `https://followmytrack.ru/posts.php?id=${window.location.href
          .split("/")
          .pop()}`
      );
      const result = await response.json();

      setCardData(result);
      setPostData(result[0].text);

      console.log("Post Dowmloaded", result);
    } catch (error) {
      console.error(`Download error: ${error.message}`);
    }
    setSpinner(false);
  };

  // редактирование одного поля одной записи

  const editOneFieldById = async (field_name, field_data, id) => {
    setSpinner(true);

    console.log("input_params->>", field_name, field_data, id);

    const date = new Date().toLocaleString("ru", {
      year: "numeric",
      month: "short",
      day: "numeric",
      timezone: "UTC",
      hour: "numeric",
      minute: "numeric",
    });

    var formdata = new FormData();

    formdata.append("field_name", field_name);
    formdata.append("field_data", field_data);
    formdata.append("date", date);
    formdata.append("id", id);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://followmytrack.ru/postEdit.php", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));

    setSpinner(false);
  };

  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );

  return (
    <div>
      {cardData && (
        <List
          key={upd_key}
          className="alphaback"
          itemLayout="vertical"
          style={{ backgroundColor: "#dcdcdc" }}
          loading={spinner}
          size="large"
          dataSource={cardData}
          // footer={
          //   <div>
          //     <span style={{ paddingLeft: "4px" }} />
          //     Последнее обновление:
          //     <i>{cardData[0].date} </i>
          //     <br />
          //     <span style={{ paddingLeft: "4px" }} />
          //     <b>FollowMyTrack </b> Post Card Module
          //   </div>
          // }
          header={
            <div>
              <font size="4">
                <Space>
                  <span style={{ paddingLeft: "4px" }} />{" "}
                  <b>{cardData[0].name} </b>
                </Space>
              </font>
              <br />
              <font size="2">
                <Space>
                  <span style={{ paddingLeft: "4px" }} /> {cardData[0].date}
                </Space>
              </font>
            </div>
          }
          //
          renderItem={(item) => (
            <List.Item
              key={item.id}
              extra={
                <>
                  {item.image && (
                    <Image width={300} alt="logo" src={item.image} />
                  )}
                  <br />
                  {item.video && (
                    <p>
                      <iframe
                        src={`https://www.youtube.com/embed/${youtube_parser(
                          item.video
                        )}`}
                        title="FollowMyTrack"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      ></iframe>
                    </p>
                  )}
                </>
              }
            >
              <List.Item.Meta
                style={{ marginBottom: "0px;" }}
                title={
                  ""
                  // item.tags ? (
                  //   <>
                  //     <Tag color="red">{item.tags}</Tag> <br /> <br />
                  //   </>
                  // ) : null
                }
                description={
                  item.audio ? (
                    <>
                      <audio controls src={item.audio}></audio>
                    </>
                  ) : null
                }
              />

              {/* <a onClick={() => setEditPost(true)}>
                ред.<font size="4">✍</font>
              </a> */}

              {!editPost ? (
                <p
                  key={upd_key}
                  dangerouslySetInnerHTML={{
                    __html: item.text
                      .replace(new RegExp("\r?\n", "g"), " <br/ > ")
                      .replace(
                        /\b(https?\:\/\/\S+)/gm,
                        '<a href="$1">[ccылка]</a>'
                      ),
                  }}
                ></p>
              ) : (
                <div>
                  <TextArea
                    rows={10}
                    defaultValue={item.text}
                    onChange={(e) => setPostData(e.target.value)}
                  />
                  <br />
                  <Space>
                    <a
                      onClick={() => {
                        editOneFieldById("text", postData, item.id);
                        message.success("Сохранено");
                        window.location.reload();
                      }}
                    >
                      Сохранить
                    </a>
                    |<a onClick={() => setEditPost(false)}>Отменить</a>
                  </Space>
                </div>
              )}
              <br />
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

export default PostCard;
